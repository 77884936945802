.signup_form{
  width: 50%;
  margin: auto;
}


.categories-nav__list {
  display: none; 
  position: absolute;
  top: 100%; 
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  z-index: 999; 
  cursor: pointer;
}

.categories-nav:hover .categories-nav__list {
  display: block; 
  cursor: pointer;
}


.categories-nav__item {
  padding: 10px;
}

.categories-nav__title{
  cursor: pointer;
}
.categories-nav__item a {
  text-decoration: none;
  color: #333;
}


.carousel .carousel-slider{
  background-color: white !important;
}

.product_html_description h1{
font-size: 13.3px !important;
font-weight: normal !important;
}
//moble css

.navigation-wrapper {
  display: flex;
  flex-direction: column;
}

.mobile {
  display: none; /* Display the mobile navigation by default */
}

.desktop {
  display: block; /* Hide the desktop navigation by default */
}
//end mobile css
.mark-mobile{
display: none;
}


.instagram_icon {
  display: inline-block;
  width: 43px;
  height: 38px;
  text-align: center;
  border-radius: 7px;
  color: #fff;
  font-size: 220px;
  line-height: 250px;
  vertical-align: middle;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
}

@media only screen and (max-width: 767px) {
.slideshow-boxed-right > .slideshow {
  
    height: auto; 
}
.static_text{
font-size: 12px;
width: 55%;
}
.top_hot_deals{
font-size: 12px;
text-decoration: none;
margin-left: 20px;
}
.responsive_banner{
width: 100%;
height: auto;
}
.shop-banner {
min-height: 7.5rem;
}
.header-top {
/* Adjust styles for header top on mobile */
display: flex;
justify-content: space-between;
}

.header-middle {
/* Adjust styles for header middle on mobile */
flex-direction: column;
align-items: center;
text-align: center;
}

.header-search {
margin-top: 10px; /* Adjust spacing */
}

.header-tools {
/* Adjust styles for header tools on mobile */
margin-top: 10px; /* Adjust spacing */
justify-content: center;
}

.navigation {
/* Adjust styles for navigation on mobile */
margin-top: 20px; /* Adjust spacing */
}

.navigation__list {
/* Adjust styles for navigation list on mobile */
flex-direction: column;
align-items: center;
}

.navigation__item {
/* Adjust styles for navigation item on mobile */
margin-bottom: 10px; /* Adjust spacing */
}
.header-desk_type_6 .categories-nav__title {
padding: -0.875rem 0 0.75rem 0.9375rem !important;
padding-left: 1.8rem !important;
padding-right: 1.8rem !important;
}
.header-desk_type_6 .categories-nav {
width: 7.325rem;

}
.categories-nav__title{
font-size: 11px;
text-align: center;
padding: 0rem !important;
margin-bottom: 86%;
}
.header-desk_type_6 .categories-nav__list {
background-color: #fff;
left: 0;
position: absolute;
top: 30%;
transition: all .3s ease;
width: 100%;
}
.categories-nav{
  display: none !important;
}

.categories-nav__item {
padding: 6px;
font-weight: 400;
line-height: 1em;
text-decoration: none;
}
.mobile {
  display: block; /* Hide the mobile navigation in mobile view */
}

.desktop {
  display: none; /* Display the desktop navigation in mobile view */
}

.responsive-class{
  width: 100% !important;
  margin-top: 1px;
}
.mark-web{
display: none;
}
.mark-mobile{
  display: block;
  margin-top: 10px;
}


}


